import "@babel/polyfill";
import VeeValidate from "vee-validate";
import VTooltip from "v-tooltip";
import Notifications from "vue-notification";
import AppNotify from "mixins/app_notifications";
import Bugsnag from '@bugsnag/js';
import BugsnagPluginVue from '@bugsnag/plugin-vue';
import VueObserveVisibility from "vue-observe-visibility";
import { ignoreErrors } from 'common/ignore_error';
import SweetEsc from "./sweet-esc";
import GlobalVariables from "./global-variables";
import { variables } from "./variables";

Vue.use(VueObserveVisibility);
Vue.use(VeeValidate, {
  events: "change|blur|postInvalidInput",
  inject: false,
});
Vue.use(VTooltip);
Vue.use(Notifications);
Vue.mixin(AppNotify);
SweetEsc(Vue);
GlobalVariables({ Vue, variables: variables() });
ignoreErrors();
const ErrorMessages = ["resizeobserver loop limit exceeded", "resizeobserver loop completed with undelivered notifications", "rejection reason was not an error.", "unhandled promise rejection",  "unhandledrejection handler",  "illegal invocation"];
window.$SiteScroll = window.$SiteScroll ?? null;
if (
  window.rails_environment &&
  (window.rails_environment === "staging" ||
    window.rails_environment === "production")
) {
  Bugsnag.start({
    apiKey: "e8a5faaa86e77ab688169e27cd34a049",
    releaseStage: window.rails_environment,
    plugins: [new BugsnagPluginVue()],
    onError: (event) => {
      if (event.errors.some(e => ErrorMessages.some(msg => e.errorMessage.toLowerCase().includes(msg) || e.errorClass.toLowerCase().includes(msg)))) {
        return false;
      }
      return true;
    },
  });
}
